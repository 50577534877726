<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <div class="col-xxl col-md-6 mb-2">
              <v-select
                class="v-select-filter"
                placeholder="Assigned.."
                v-model="assigned"
                :options="[
                  { label: 'Assigned to', value: 'to' },
                  { label: 'Assigned by', value: 'by' },
                ]"
                :searchable="false"
                @input="inputFilter"
              />
            </div>

            <!-- User Filter -->
            <div class="col-xxl col-md-6 mb-2">
              <v-select
                class="v-select-filter"
                placeholder="Select admin user.."
                v-model="selectedAdmin"
                :options="adminUsers"
                @input="inputFilter"
              />
            </div>

            <!-- Restaurant Filter -->
            <div class="col-xxl col-md-6 mb-2">
              <v-select
                class="v-select-filter"
                placeholder="Select restaurant.."
                v-model="selectedRestaurant"
                :options="allRestaurants"
                @input="inputFilter"
                :disabled="!!(restaurantId)"
              />
            </div>

            <!-- Task Status -->
            <div class="col-xxl col-md-6 mb-2 mb-md-0">
              <v-select
                class="v-select-filter"
                placeholder="Select task status.."
                v-model="status"
                :options="$globalVar.emailLabels"
                :searchable="false"
                @input="inputFilter"
                multiple
              >
                <template v-slot:option="label">
                  <CBadge :color="label.color" class="my-1" style="font-size: 85%;">{{ label.label }}</CBadge>
                </template>
              </v-select>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-3 mb-2 mb-md-0">
              <CInput
                type="date"
                :lazy="false"
                :max="new Date().toISOString().slice(0,10)"
                class="mb-0"
                v-model="created_at"
                @input="inputFilter"
              />
            </div>
            <div class="col-lg-9 mb-0">
              <CInput
                class="mb-0"
                type="search"
                placeholder="Search.."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <!-- Task Table -->
    <TaskTable
      :tasks="tasks"
      :loading="loading"
      @taskChanged="getAllTask"
    />

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>
  </CCardBody>
</template>

<script>
import { mapState } from 'vuex'
import TaskTable from "./TaskTable";
import _debounce from 'lodash/debounce';

export default {
  name: "BTTaskList",
  components: {
    TaskTable
  },
  data() {
    return {
      tasks: [],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      loading: false,

      // Filter işlemleri için
      allRestaurants: [],
      adminUsers: [],
      created_at: null,
      search: null,
    };
  },

  beforeMount() {
    this.tasks = this.activePage == 1 && (JSON.parse(localStorage.getItem('all-tasks')) || []);

    this.searchFilter = _debounce(() => {
      this.getAllTask();
    }, 1000);
  },

  async mounted() {
    this.activePage =  this.queryPage;

    await Promise.all([
      this.getRestaurants(),
      this.getAdminUsers(),
    ]);
    await this.getAllTask();
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage) {
        this.$router.push({ name: this.$route.name, query: { page: this.activePage } });
      }
      this.onTableChange();
    },
    tasks: { handler: 'updateStorage' },
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage] },
    queryPage() { return parseInt(this.$route.query.page) || 1 },
    restaurantId() { return this.$route.params.restaurant_id },

    assigned: {
      get() { return this.filters.task.assigned },
      set(value) { this.filters.task.assigned = value },
    },
    selectedAdmin: {
      get() { return this.filters.task.selectedAdmin },
      set(value) { this.filters.task.selectedAdmin = value },
    },
    selectedRestaurant: {
      get() { return this.filters.task.selectedRestaurant },
      set(value) { this.filters.task.selectedRestaurant = value },
    },
    status: {
      get() { return this.filters.task.status },
      set(value) { this.filters.task.status = value },
    },
  },

  methods: {
    async getAllTask() {
      this.loading = true;

      var url = new URL(this.$backend.CRM_TASKS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,

          'user-id': this.selectedAdmin?.value,
          'assigned': this.assigned?.value,
          'status': this.status.length ? this.status.map(s => s.value) : null,
          'restaurant-id': this.selectedRestaurant?.value,
          'created-at': this.created_at,
          search: this.search,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.tasks = items.data.map((el) => {
              return {
                ...el,
                labelObj: this.getLabel(el.label),
                deleting: false
              };
            });
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getLabel(value) {
      return value ? this.$globalVar.emailLabels.find((el) => el.value === value) : null;
    },

    async getRestaurants() {
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_NAME)
        .then((response) => {
          this.allRestaurants = response.data;

          if(this.restaurantId) {
            this.selectedRestaurant = this.allRestaurants.find(item => item.value == this.restaurantId);
          }
        })
        .catch(() => { this.allRestaurants = [] })
    },
    async getAdminUsers() {
      await this.$axios
        .get(this.$backend.USERS.GET_ADMIN_USERS)
        .then((response) => {
          this.adminUsers = response.data;
        })
        .catch(() => { this.adminUsers = [] })
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllTask();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllTask();
    },

    // Filters
    inputFilter() {
      this.getAllTask();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.assigned = this.selectedAdmin = null;
        !this.restaurantId && (this.selectedRestaurant = null);
        this.getAllTask();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    updateStorage() {
      this.activePage == 1 && localStorage.setItem('all-tasks', JSON.stringify(this.tasks));
    }
  },
};
</script>
