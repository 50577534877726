<template>
  <CRow>
    <CCol sm="12">
      <CCard>

        <CCardHeader>
          <CIcon name="cil-bolt" />
          <h5 class="d-inline ml-2">Task List</h5>
          <div class="card-header-actions">
            <CButton color="primary" :to="{ name: 'Compose'}">
              <CIcon name="cil-plus" size="lg" /><strong>New</strong> Task
            </CButton>
          </div>
        </CCardHeader>

        <BTTaskList />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import BTTaskList from "./BTTaskList";

export default {
  name: "TaskList",
  components: {
    BTTaskList,
  }
};
</script>
